import React from 'react'
import { Link } from 'gatsby'

import SiteLayout from '../layout/SiteLayout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import img from '../images/svg/404/img.svg'
import styles from './404.module.scss'

const NotFoundPage = () => (
  <SiteLayout>
    <SEO title="404: Not found" />
    <Container>
      <div className={styles.container}>
        <img className={styles.img} src={img} alt="404 illustration" />
        <h1 className="pt-4">That's an error...</h1>
        <p>This page does not exist.</p>
        <Link to="/" className="btn btn-primary mt-3">
          Go back to main website
        </Link>
      </div>
    </Container>
  </SiteLayout>
)

export default NotFoundPage
